<template>
  <div class="feature-list" :class="themeColorClass">
    <UiHeader
      v-if="header"
      class="feature-list__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <div v-if="itemList.length" class="feature-list__list-wrapper">
      <div v-if="firstItemList.length" class="feature-list__list">
        <FeatureListItem
          v-for="item in firstItemList"
          :key="item.id"
          :item="item"
        />
      </div>

      <div v-if="secondItemList.length" class="feature-list__list">
        <FeatureListItem
          v-for="item in secondItemList"
          :key="item.id"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeProductBoxesProps } from '~/types'
import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'

import FeatureListItem from './FeatureListItem.vue'

const props = defineProps<UiCeProductBoxesProps>()
const themeColorClass = useTheming(props.themeColor)

const splitItemList = computed(() => {
  const splitIndex = Math.floor(props.itemList.length / 2)

  return [props.itemList.slice(0, splitIndex), props.itemList.slice(splitIndex)]
})

const [firstItemList, secondItemList] = splitItemList.value
</script>

<style lang="scss">
.feature-list {
  padding-top: rem(24px);

  &__list-wrapper {
    display: flex;
    gap: rem(36px);

    @include media-query(max-width md) {
      flex-direction: column;
    }
  }

  &__heading + &__list-wrapper {
    margin-top: rem(52px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(48px);
    width: 100%;
  }
}
</style>
